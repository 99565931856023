import React, { useState } from "react";
import {
  useUpdateIdea,
  useGetUser,
  useGetUserDataById,
  useNotifyJoinIdea,
  useNotifyLeaveIdea,
} from "../../hooks";
import {
  ConfirmJoinModal,
  ConfirmLeaveModal,
  LoadingModal,
  EditIdeaModal,
  ConfirmDeleteIdeaModal,
  CannotDeleteIdeaModal,
} from "../../modals";
import { tw } from "@didomi/ui-foundation";
import {
  DidomiIllustration,
  DidomiIconButton,
  DidomiChipList,
  DidomiChip,
  DidomiButton,
} from "@didomi/ui-atoms-react";
import { SubscribersList } from "../index";
import { useNavigate } from "react-router-dom";
import { useDeleteIdea } from "../../hooks/useDeleteIdea";

const IdeaCard = ({
  uid,
  name,
  description,
  createdBy,
  example,
  subscribedBy,
  isParticipating,
  help,
  "data-testid": dataTestid,
  expanded = true,
}) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [displayConfirmJoinModal, setDisplayConfirmJoinModal] = useState(false);
  const [displayConfirmLeaveModal, setDisplayConfirmLeaveModal] =
    useState(false);
  const [displayEditIdeaModal, setDisplayEditIdeaModal] = useState(false);
  const [displayDeleteIdeaModal, setDisplayDeleteIdeaModal] = useState(false);

  const user = useGetUser();

  const { user: createdByName } = useGetUserDataById(createdBy);

  const { notifyJoinIdea } = useNotifyJoinIdea();

  const { notifyLeaveIdea } = useNotifyLeaveIdea();

  const { updateIdea, isLoading: isUpdatingIdea } = useUpdateIdea(uid);

  const { deleteIdea, isLoading: deletingIdea } = useDeleteIdea(uid);

  const isUsersIdea = createdBy === user.uid;
  const isUserSubscribedToIdea = subscribedBy?.includes(user.uid);
  const hasSubscribers = subscribedBy?.length > 0;
  const canJoin =
    !isParticipating &&
    !isUsersIdea &&
    (subscribedBy?.length < 4 ||
      !subscribedBy ||
      (createdBy === "Dl2Qf1DanvRoWfo2gEYa1J14UTR3" &&
        subscribedBy?.length < 5));
  const canLeave = isUserSubscribedToIdea && !isUsersIdea;
  const showBottomBar = !example;

  const handleToggle = () => {
    setIsExpanded((e) => !e);
  };

  return (
    <>
      <div
        className="p-1 bg-neutral-gray-1 rounded-lg w-full"
        data-testid={dataTestid}
      >
        <div
          className={tw(
            "bg-white rounded-lg",
            "border-neutral-gray-4 border-1 border-solid",
            {
              "border-primary-blue-2 border-2":
                isUsersIdea || isUserSubscribedToIdea,
            },
            "flex flex-col"
          )}
        >
          <div className="p-6 flex items-center">
            <DidomiIllustration name="purpose" />
            <button
              className="flex! flex-col items-start mx-s border-0 outline-none bg-white"
              onClick={() => navigate(`/idea/${uid}`)}
            >
              <div className="text-primary-blue-4 font-sans font-semibold text-xs">
                Hackathon Idea
              </div>
              <div className="text-primary-blue-6 font-semibold text-body-big leading-6 text-left">
                {name}
              </div>
            </button>
            {example && (
              <DidomiChip
                variation="basic-outline"
                label="Example"
                basicType="neutral"
              />
            )}
            <div className="flex-1 mr-xs" />
            {subscribedBy?.length === 4 && (
              <DidomiChip
                className="mr-s"
                label="Team is full"
                variation="basic-outline"
                basicType="success"
              />
            )}
            <DidomiIconButton
              data-testid="toggle-purposes-card"
              title={expanded ? "Collapse purpose card" : "Expand purpose card"}
              variant="rounded-border"
              aria-expanded={expanded ? "true" : "false"}
              icon={expanded ? "up-on-sm" : "down-on-sm"}
              onClick={handleToggle}
            />
          </div>
          <div
            className={tw(
              "px-6 overflow-hidden pt-xs relative",
              isExpanded ? "flex flex-col" : "hidden"
            )}
          >
            <div className="mb-s font-sans text-body-normal text-primary-blue-6 whitespace-pre-line">
              {description}
            </div>

            {help?.length > 0 && (
              <div className="pb-6">
                <div className="flex items-center border-top-1 border-solid border-primary-blue-4">
                  <div className="font-sans text-body-small leading-body-small italic text-primary-blue-6 mr-xxs">
                    Needs help with:
                  </div>
                  <DidomiChipList>
                    <div>
                      {help.map((h) => (
                        <DidomiChip
                          key={name + "-" + h}
                          variation="basic-outline"
                          label={h}
                        />
                      ))}
                    </div>
                  </DidomiChipList>
                </div>
              </div>
            )}
          </div>

          {showBottomBar && (
            <div className="pb-6 border-t-1 border-solid border-neutral-grey-4">
              <div className="px-6 pt-6 flex justify-end">
                {createdByName && (
                  <DidomiChip
                    className="mr-xxs"
                    label={createdByName?.name}
                    variation={
                      createdBy === "Dl2Qf1DanvRoWfo2gEYa1J14UTR3"
                        ? "basic-outline"
                        : "basic"
                    }
                    basicType="info"
                  />
                )}
                <SubscribersList name={name} subscribedBy={subscribedBy} />
                <div className="flex-1" />
                <DidomiButton
                  size="small"
                  variant="secondary"
                  onClick={() => navigate(`/idea/${uid}`)}
                >
                  View Details
                </DidomiButton>
                <DidomiButton
                  size="small"
                  className="ml-xs!"
                  disabled={example || !canJoin}
                  onClick={() => setDisplayConfirmJoinModal(true)}
                >
                  Join!
                </DidomiButton>
                {isUsersIdea && (
                  <>
                    <DidomiButton
                      size="small"
                      variant="top"
                      className="ml-xs!"
                      onClick={() => setDisplayEditIdeaModal(true)}
                    >
                      Edit
                    </DidomiButton>
                    <DidomiButton
                      size="small"
                      variant="secondary"
                      className="ml-xs!"
                      onClick={() => setDisplayDeleteIdeaModal(true)}
                    >
                      Delete
                    </DidomiButton>
                  </>
                )}
                {canLeave && (
                  <DidomiButton
                    size="small"
                    variant="top"
                    className="ml-xs!"
                    onClick={() => setDisplayConfirmLeaveModal(true)}
                  >
                    Leave
                  </DidomiButton>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <ConfirmJoinModal
        isOpen={displayConfirmJoinModal}
        onClose={() => setDisplayConfirmJoinModal(false)}
        onChange={(e) => setDisplayConfirmJoinModal(e.detail)}
        onSave={() => {
          try {
            updateIdea({
              subscribedBy: subscribedBy?.length
                ? subscribedBy.concat(user.uid)
                : [user.uid],
              });
                
            notifyJoinIdea({ idea: { name, id: uid }, user });
          } catch (_) {}
          setDisplayConfirmJoinModal(false);
        }}
      />
      <ConfirmLeaveModal
        isOpen={displayConfirmLeaveModal}
        onClose={() => setDisplayConfirmLeaveModal(false)}
        onChange={(e) => setDisplayConfirmLeaveModal(e.detail)}
        onSave={() => {
          try {
            updateIdea({
              subscribedBy: subscribedBy?.filter((id) => id !== user.uid),
            });
            
            notifyLeaveIdea({ idea: { name, id: uid }, user });
          } catch (_) {}
          setDisplayConfirmLeaveModal(false);
        }}
      />
      <EditIdeaModal
        idea={{ name, description, help }}
        isOpen={displayEditIdeaModal}
        onClose={() => setDisplayEditIdeaModal(false)}
        onChange={(e) => setDisplayEditIdeaModal(e.detail)}
        onSave={(idea) => {
          updateIdea(idea);
          setDisplayEditIdeaModal(false);
        }}
      />
      <CannotDeleteIdeaModal
        isOpen={displayDeleteIdeaModal && hasSubscribers}
        onChange={(e) => setDisplayDeleteIdeaModal(e.detail)}
        onSave={() => setDisplayDeleteIdeaModal(false)}
      />
      <ConfirmDeleteIdeaModal
        isOpen={displayDeleteIdeaModal && !hasSubscribers}
        onClose={() => setDisplayDeleteIdeaModal(false)}
        onChange={(e) => setDisplayDeleteIdeaModal(e.detail)}
        onSave={() => {
          deleteIdea();
          setDisplayDeleteIdeaModal(false);
        }}
      />
      <LoadingModal isOpen={isUpdatingIdea || deletingIdea} />
    </>
  );
};

export { IdeaCard };
